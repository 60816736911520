<template>
  <div>
    <div class="row q-pa-md">
      <q-uploader
        url="http://localhost:4444/upload"
        label="Add files"
        multiple
        batch
        flat
        bordered
        color="primary glossy"
        style="width: 32rem; max-width: 100%;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BundleUpload',

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
