<template>
  <q-layout view="hHh lpR fFf" class="bg-grey-2">
    <q-header class="glossy bg-dark">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title>Neuronic</q-toolbar-title>
        <div>v.210406</div>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" show-if-above bordered>
      <q-list class="q-pt-sm">
        <q-item-label header>Neuronic Bundles</q-item-label>
        <q-item clickable @click="$router.push({name:'home'})">
          <q-item-section avatar>
            <q-icon name="cloud_upload" color="secondary" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Upload</q-item-label>
            <q-item-label caption>Process new images</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable @click="$router.push({name:'bundle'})">
          <q-item-section avatar>
            <q-icon name="center_focus_strong" color="accent" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Results</q-item-label>
            <q-item-label caption>Check processed bundles</q-item-label>
          </q-item-section>
        </q-item>
        <q-item-label header>About</q-item-label>
        <q-item clickable tag="a" target="_blank" href="https://quasar.dev">
          <q-item-section avatar>
            <q-icon name="help_outline" color="warning" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Info</q-item-label>
            <q-item-label caption>What is Neuronic?</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>

export default {
  name: 'LayoutDefault',

  components: {
  },

  data() {
    return {
      leftDrawerOpen: false
    }
  }
}
</script>

<style>
</style>
