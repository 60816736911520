import { render, staticRenderFns } from "./BundleUpload.vue?vue&type=template&id=6829e3b6&scoped=true&"
import script from "./BundleUpload.vue?vue&type=script&lang=js&"
export * from "./BundleUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6829e3b6",
  null
  
)

export default component.exports
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QUploader});
