import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    DOWNLOAD_RESULTS(context, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: "https://google.pl".replace("{id}", id)
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        });
      })
    },
  },
  modules: {
  }
})
