<template>
  <div>
    <BundleUpload />
  </div>
</template>

<script>
// @ is an alias to /src
import BundleUpload from '@/components/BundleUpload.vue'

export default {
  name: 'HomeView',
  components: {
    BundleUpload
  }
}
</script>
